@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import "./variables.scss";
@font-face {
    font-family: Assistant-Bold;
    src: url("../fonts/Assistant-Bold.ttf");
}

@font-face {
    font-family: Assistant-ExtraBold;
    src: url("../fonts/Assistant-ExtraBold.ttf");
}

@font-face {
    font-family: Assistant-ExtraLight;
    src: url("../fonts/Assistant-ExtraLight.ttf");
}

@font-face {
    font-family: Assistant-Light;
    src: url("../fonts/Assistant-Light.ttf");
}

@font-face {
    font-family: Assistant-Medium;
    src: url("../fonts/Assistant-Medium.ttf");
}

@font-face {
    font-family: Assistant-Regular;
    src: url("../fonts/Assistant-Regular.ttf");
}

@font-face {
    font-family: Assistant-SemiBold;
    src: url("../fonts/Assistant-SemiBold.ttf");
}

@font-face {
    font-family: Rubik-Black;
    src: url("../fonts/Rubik-Black.ttf");
}

::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #adadadb7;

    &:hover {
        background: #868686b7;
    }
}

::-webkit-scrollbar {
    width: 0px;
    // width: 10px;
    border-radius: 30px;
}

.remove-scrollbar {
    ::-webkit-scrollbar {
        width: 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: #00000000;

        &:hover {
            background: #00000000;
        }
    }
}

.App {
    text-align: center;
    font-family: "Assistant-Regular";
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}

* {
    font-family: "Assistant", sans-serif;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.gridify {
    .grid-section {
        margin: 15px auto 52px;
    }

    .grid-title {
        text-align: initial;
        font-family: Assistant-Bold;
        color: $teal;
        font-size: 18px;
        margin-bottom: 25px;

        &.sub-title {
            font-size: 12px !important;
            margin: -15px 0 25px 0 !important;
        }
    }

    .MuiGrid-grid-xs-6 {
        max-width: 46%;
        flex-basis: 46%;

        input:-webkit-autofill {
            -webkit-text-fill-color: $teal;
        }
        input:-webkit-autofill:focus {
            -webkit-text-fill-color: $teal;
        }
    }

    & > button {
        font-family: Assistant-Bold;
    }
}

//todo remove
.MuiPickersStaticWrapper-staticWrapperRoot,
.MuiPickersBasePicker-container {
    direction: rtl !important;
}
