@import "./variables.scss";
body {
    padding: 0 !important;
    margin: 0;
    font-family: Assistant-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MuiFormLabel-root {
    font-family: Assistant-Regular;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #fdfdfd inset;
    -webkit-text-fill-color: $teal;
}
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #fdfdfd inset;
    -webkit-text-fill-color: $teal;
}
